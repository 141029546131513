/* eslint-disable import/no-duplicates */
import axios, { AxiosError } from 'axios'
import { getUserSessionStorage } from '@/composables/useSessions'
import useSessions from '@/composables/useSessions'

import { setSnackbar } from '@/composables/useSnackbar'
import router from '@/router'

const httpClient = axios.create({
  baseURL: `${process.env.VUE_APP_EXPRESSWAY_URL}/private`,
  timeout: 16000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_EXPRESSWAY_API_KEY
  }
})

const httpClientBasic = axios.create({
  baseURL: `${process.env.VUE_APP_EXPRESSWAY_URL}/private`,
  timeout: 16000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_EXPRESSWAY_API_KEY
  }
})

const parseErrorMessage = (error: AxiosError) => {
  const message = error.response?.data?.Message
  if (typeof message === 'string') {
    return message
  } else if (Array.isArray(message)) {
    return message.map(obj => Object.values(obj)[0]).join(', ')
  } else {
    return error.message
  }
}

httpClient.interceptors.request.use(
  config => {
    const userSession = getUserSessionStorage()
    if (userSession?.AccessToken) {
      config.headers.Authorization = `Bearer ${userSession.AccessToken}`
    } else {
      delete config.headers.Authorization
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  response =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response
  ,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      const userSession = getUserSessionStorage()
      if (userSession) {
        const { logOut } = useSessions()
        logOut(true)
        router.push({ name: 'Login' }).then(() =>
          setSnackbar('Access denied. You must sign in to continue.')
        )
      } else {
        router.push({ name: 'Login' }).then(() =>
          setSnackbar('Access denied. You must sign in to continue.')
        )
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    error.message = parseErrorMessage(error)
    return Promise.reject(error)
  }
)

export { httpClient, httpClientBasic }
